import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AboutUs, Home, ContactUs, AllClientPage, AllServicesPage, LoginPage, Error, WebDevelopment } from './components';
import "./assets/css/style.css"
import License from './components/partials/License';

// // Admin Route
import AdminHome from './components/Admin/AdminHome';
import AdminLicense from './components/Admin/Licenses/AdminLicense';
import AdminCreateLicense from './components/Admin/Licenses/AdminCreateLicense ';
import AdminProfileUpdate from './components/Admin/AdminProfileUpdate';
import AdminUpdateLicense from './components/Admin/Licenses/AdminUpdateLicense'
import AdminCreateClient from "./components/Admin/Clients/AdminCreateClient"
import AdminClient from './components/Admin/Clients/AdminClient';
import AdminUpdateClient from './components/Admin/Clients/AdminUpdateClient';

// Admin Testimonial
import AdminCreateTestimonial from './components/Admin/Testimonial/AdminCreateTestimonial';
import AdminTestimonial from './components/Admin/Testimonial/AdminTestimonial';
import AdminAllQuery from './components/Admin/Query/AdminAllQuery';
import Navbar from './components/partials/Navbar';
import Footer from './components/partials/Footer';
import AdminWebQuery from './components/Admin/WebQuery/AdminWebQuery';
import AdminCreateProfile from './components/Admin/AdminCreateProfile';
import AdminAllUsers from './components/Admin/User/AdminAllUsers';
import ForgetPassword from './components/ForgetPassword';
import ForgetPassword1 from './components/ForgetPassword1';
import ForgetPassword2 from './components/ForgetPassword2';
import Confirm from './components/Confirm';
import {Helmet} from "react-helmet"
import ExportAndExport from './components/services/ExportAndExport';
import DDAProperty from './components/services/DDAProperty';
import DelhiPolice from './components/services/DelhiPolice';
import Fssai from './components/services/Fssai';
import DpccLice from './components/services/DpccLice';
import McdHealthTrade from './components/services/McdHealthTrade';
import SocialMediaMangement from './components/pages/SocialMediaMangement';
import CommingSoon from './components/pages/CommingSoon';

export default function App() {
  //  const isAuthorization = !!localStorage.getItem("login")
  //  const isAdmin =  localStorage.getItem("role")==="Admin;"
  
   
  return (
    <BrowserRouter>
    <Navbar/>
    <Helmet>
      <title>PGC | Home</title>
      <meta name="description" content="Perfect global consultancy is a license and web devlopment company . It is Delhi all type license provide company.
      PGC last 15 years  experince of license providing . so pls visit my website. " />
      <meta name="keywords" content="fssai,FSSAI, fssai license, dpcc,Web Development, webdevelopment , delhipolice,
      eatinghouse, guesthouse, mcd, mcdlicense,webdisgn, excide,gst, gstfillig,tradmark,fire/noc, noc,
      delhipolicefire,delhilicense, license, lisense, L-17,P-10,p-10,l-17,gst , MCD delhi,    " />
    </Helmet>
        <Routes>
          {/* user routes */}
            <Route path='/' element={<Home />}/>
            <Route path='/about-us' element={<AboutUs />}/>
            <Route path='/contact-us' element={<ContactUs />}/>
            <Route path='/services/:_id' element={<License />}/>
            <Route path='/allclient-page' element={<AllClientPage />}/>
            <Route path='/allservices-page' element={<AllServicesPage />}/>
            <Route path='/webdevelopment' element={<WebDevelopment/>}/>
            <Route path='/export-import' element={<ExportAndExport/>}/>
            <Route path='/dda-property' element={<DDAProperty/>}/>
            <Route path='/eating-house' element={<DelhiPolice/>}/>
            <Route path='/fssai' element={<Fssai/>}/>
            <Route path='/dpcc-license' element={<DpccLice/>}/>
            <Route path='/mcd-health-trade' element={<McdHealthTrade/>}/>
            {/* webdevelopment sevices */}
            {/* <Route path='/coming' element={<CommingSoon/>}/> */}
            <Route path='/socialmedia' element={<SocialMediaMangement/>}/>



       {/* Admin route */}
       
           
      <Route path='/login' element={<LoginPage/>}/>
         
       
      <Route path='/forget-password1' element={<ForgetPassword1/>}/>
      <Route path='/forget-password' element={<ForgetPassword/>}/>
      <Route path='/forget-password2' element={<ForgetPassword2/>}/>
      <Route path='/admin/create/profile' element={<AdminCreateProfile/>}/>

      <Route path='/confirm' element={<Confirm/>}/>
       { 
      localStorage.getItem("login") && localStorage.getItem("role")==="Admin" ?
       <>
       <Route path='/admin' element={<AdminHome />}/>


       {/* admin License route */}
       <Route path='/admin/license' element={<AdminLicense/>}/>
       <Route path='/admin/license/create' element={<AdminCreateLicense/>}/>
       <Route path='/admin/profile/update' element={<AdminProfileUpdate/>}/>
       <Route path='/admin/license/update/:_id' element={<AdminUpdateLicense/>}/>
        {/* Admin Testimonial Route  */}
       <Route path='/admin/testimonial' element={<AdminTestimonial/>}/>
        <Route path='/admin/testimonial/create' element={<AdminCreateTestimonial/>}/>

        {/* admin Client Route */}
        <Route path='/admin/client/create' element ={<AdminCreateClient/>}/>
        <Route path='/admin/client/update/:_id' element ={<AdminUpdateClient/>}/>
        <Route path='/admin/client' element ={<AdminClient/>}/>
        {/* Admin query Route */}
       <Route path='/admin/allquery' element={<AdminAllQuery/>}/>
       <Route path='/admin/webquery' element={<AdminWebQuery/>}/>
       <Route path='/admin/user' element={<AdminAllUsers/>}/>
       </>
     :""      }
      : <Route path='/*' element={<Error/>}/>
        </Routes>
        <Footer />
    </BrowserRouter>
    
  )
}

