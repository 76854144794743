import React, { useEffect, useState } from 'react'
import { Link, useParams} from 'react-router-dom'
// import data from '../../Data/data';
import OurServices from './OurServices';
import QueryForm from './QueryForm';
import { getLicense } from '../../Store/ActionCreaters/LicenseActionCreaters';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

export default function License() {
let  [data ,setData]=useState()
let [pageRefresh, setPageRefresh]= useState(false)
let {_id} =useParams();
// console.log("paramsId", paramsId)

let dispatch = useDispatch()
let LicenseStateData = useSelector((x)=>x.LicenseStateData)

useEffect(()=>{
(()=>{
    dispatch(getLicense())
    if(LicenseStateData.length){
      let item = LicenseStateData.find((x)=>x?._id === _id)
      if(item){
        setData(item)
      }
    }
})()

},[LicenseStateData?.length])

  return (
<>
<Helmet>
      <title>{`PGC | ${data?.name}`}</title>
      <meta name="description" content="Perfect global consultancy is a license and web devlopment company . It is Delhi all type license provide company.
      PGC last 15 years  experince of license providing . so pls visit my website. " />
      <meta name="keywords" content="fssai,FSSAI, fssai license, dpcc,Web Development, webdevelopment , delhipolice,
      eatinghouse, guesthouse, mcd, mcdlicense,webdisgn, excide,gst, gstfillig,tradmark,fire/noc, noc,
      delhipolicefire,delhilicense, license, lisense, L-17,P-10,p-10,l-17,gst , MCD delhi,    " />
    </Helmet>

<div className="container-fluid">
      <div className="row">
      <div className="col-12 lic-heading ">
       <h1 className='text-center'>{data?.aboutLicense}</h1>
        <p  className='text-center'>{data?.aboutLicenseDetails}</p>
        
      </div>
    </div>


   <div className="row m-auto py-2 ">
    {
      data?.licenseHeading?.length  && data?.licenseHeadingDetails?.length?
      <div className="col-10 m-auto  bg-light  lic-card ">
      <h3 className=''>{data?.licenseHeading}  </h3>
      <p style={{fontFamily:"Arial, Helvetica, sans-serif",textAlign:"justify"}}>{data?.licenseHeadingDetails}</p>
    </div>
    :""
    }
    </div>
    {
      data?.requiredDocumentHeading ?
      <div className="row m-auto py-2 ">
      <div className=" card col-10  m-auto bg-light lic-card ">
      <h3>{data?.requiredDocumentHeading}</h3>
       {
        data.document1 ?.length?
      <p>{data.document1}</p>:""
       }
        {
        data.document2?.length?
      <p>{data.document2}</p>:""
       }
      {
        data.document3?.length? 
        <p style={{fontWeight:"lighter"}}>&#x2022;  {data?.document3} </p>:
        ""
      }
       {
        data.document4?.length? 
        <p style={{fontWeight:"lighter"}}>&#x2022;  {data?.document4} </p>:
        ""
      }
       {
        data.document5?.length? 
        <p style={{fontWeight:"lighter"}}>&#x2022;  {data?.document5} </p>:
        ""
      }
       {
        data.document6?.length? 
        <p style={{fontWeight:"lighter"}}>&#x2022;  {data?.document6} </p>:
        ""
      }
      {
        data.document7?.length? 
        <p style={{fontWeight:"lighter"}}>&#x2022;  {data?.document7} </p>:
        ""
      }
      {
        data.document8?.length? 
        <p style={{fontWeight:"lighter"}}>&#x2022;  {data?.document8} </p>:
        ""
      }
      <p>{data?.document9}</p>
    
      </div>
      </div>
      :""
    }
    
{/* contactus  */}
    <div className="container-xxl py-5 mb-2">
            <div className="container lic-contactUs">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h1 className="mb-5">Connect  with <span className="text-primary text-uppercase"> Us</span></h1>
                    <h6 className="section-title text-center text-primary text-uppercase">Connect with our team, lets
                    have a discussion 24X7 </h6>
                </div>
                <div className="row g-4 ">
                    
                    <div className="col-lg-4  col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className=" card service-icon">
                                <div className="border rounded d-flex  justify-content-center" style={{height:"200px"}}>
                               <Link to="tel:+919971522879" >
                               <img src="/img/telephone.svg" alt='Phone Img' style={{height:"80px"}} />
                                <h5 className='contact-h5'>Phone</h5>
                               </Link>
                                </div>
                            </div>
                    </div>

                    <div className="col-lg-4  col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className=" card service-icon">
                                <div className="border rounded d-flex  justify-content-center" style={{height:"200px"}}>
                               <Link to="mailto:perfectglobal@gmail.com" target="_blank"> <img src="/img/envelope.svg" alt='Email img' style={{height:"80px"}} />
                                <h5 className='contact-h5'>Email</h5>
                               </Link>
                                </div>
                            </div>
                    </div>

                    <div className="col-lg-4  col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className=" card service-icon">
                                <div className="border rounded d-flex  justify-content-center" style={{height:"200px"}}>
                               <Link target='_blank' to="https://wa.me/+919971522879" ><img src="/img/whatsapp.svg" alt='Whats app img' style={{height:"80px"}} />
                                <h5 className='contact-h5'>Whats App</h5>
                               </Link>
                                </div>
                            </div>
                    </div>
                   
                </div>
            </div>
        </div>
        

      <QueryForm/>

{/* Related services */}
    <h3 className='text-center'>Related Services we Offer</h3>
    <div className="row">

          {/* {data.slice(0,8).map((item, index) => {
            return (
              <div className=" col-lg-3 col-md-4 col-sm-6  wow fadeInUp col-12 " key={index}>
                <div
                  className="card my-2 my-4 mx-2 mx-auto licenseCard"
                  style={{ width: "18rem" }}
                >
                  <img
                    src="./img/fssai.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                   <h4> <Link to="/fssai-license"> {item.name}</Link></h4>
                    <p className="card-text">
                     {item.bio}
                    </p>
                  </div>
                </div>
              </div>
            );
          })} */}

        </div>

        <OurServices/>
</div>

</>

)
}
