import React from 'react'
import Testimonials from "../../components/partials/Testimonials"
import { Link } from 'react-router-dom'
import QueryForm from '../partials/QueryForm'
export default function SocialMediaMangement() {
  return (
    <>
    <div className="container-fluid social-main">
        <div className="row">
            <div className="col-md-12  social-banner">
                <h1 className='text-center'>Social Media Management</h1>
            </div>

            {/* media description  */}
            <div className="col-md-12   social-descr ">
                <p className='text-center mb-3'>Social Media Management</p>
                <p>Social Media has become an integral part of online marketing and is looked upon by companies of all sizes. Social Media Management is a process of managing your online interactions and content across social media channels like Facebook, Instagram, Twitter, LinkedIn, YouTube, and Pinterest.</p>
                <p>Social Media has become an integral part of online marketing and is looked upon by companies of all sizes. Social Media Management is a process of managing your online interactions and content across social media channels like Facebook, Instagram, Twitter, LinkedIn, YouTube, and Pinterest.</p>
                   {/* <Link to="/contact-us" className=" text-center fs-5 tellus" style={{ width:"15rem", margin:"auto", }}  > TELL US ABOUT YOUR PROJECT </Link> */}
                  <div className="tellus-div">
                  <button className='btn tellusbtn' > <Link to="/contact-us">TELL US ABOUT YOUR PROJECT </Link></button>
                  </div>
               
            </div>
        </div>
        <div className="row social-about">
            <div className="col-md-6 social-about-left ">
                <img src="/web/social-laptop.jpg" alt="" />

            </div>
            <div className="col-md-6 social-about-right">
                <h1>Brand Strategy & Management</h1>
                <p>Explore the ins and outs of social media management with this complete guide. We unpack the key components of social media management and what’s critical to make sure your brand stays relevant.Branding defines who you are, your company’s identity, personality, and values. These things should be reflected in everything you do, from product development to marketing and more. For your brand to break through the clutter, you need a robust road map, managed by experts. We help you build a strong brand identity, based on your brand values.</p>
                <div className="tellus-div">
                  <button className='btn tellusbtn ' > <Link to="/contact-us">REQUEST A FREE QUOTE</Link></button>
                  </div>
            </div>
        </div>

{/* work with us  */}
        <div className="row work-with-main">
            <div className="col-md-5  ">
                <h1>Work With US</h1>
                <div className='underline-work-with'></div>
                <p>We can create creative and sterling marketing solutions for your bussiness!</p>
                <div className="tellus-div">
                  <button className='btn work-with-btn' > <Link to="/contact-us">LET'S PLAN A MEETING </Link></button>
                  </div>
            </div>
            <div className="col-md-6 mb-5 ">
            <div className="skill-mf">
                  {/* <p className="title-s ">Skill</p> */}
                  <span className='title-n'>Content Marketing Strategy</span> <span className="pull-right title-n">62%</span>
                  <div className="progress mb-1">
                    <div className="progress-bar" role="progressbar" style={{width: "62%"}} aria-valuenow="62" aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                  <span className='title-n'>Digital Consultancy</span> <span className="pull-right title-n">85%</span>
                  <div className="progress mb-1">
                    <div className="progress-bar bg-success" role="progressbar" style={{width: "85%"}} aria-valuenow="85" aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                  <span className='title-n'>Mobile Marketing</span> <span className="pull-right title-n">52%</span>
                  <div className="progress mb-1">
                    <div className="progress-bar bg-danger" role="progressbar" style={{width: "52%"}} aria-valuenow="52" aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                  <span className='title-n'>Reputation Management</span> <span className="pull-right title-n">42%</span>
                  <div className="progress mb-1">
                    <div className="progress-bar bg-primary" role="progressbar" style={{width: "42%"}} aria-valuenow="42" aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
             </div>
              

            </div>
        </div>

        {/* testimonials or Our services */}
        <div className="row media-testimonials">
            <div className="col-md-8">
                    <Testimonials/>
            </div>

            <div className="col-md-4 mt-3">
        <h3 className='text-center mt-3'> OUR <u className='text-warning' style={{fontFamily:"Roboto , sans-serif"}}>WORKS</u> </h3>

       <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        Social Media Consultant
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
            Our sole purpose - attract and retain customers by consisiternly creating relevant valuable  content with the intention of changing or enchacing consumer behavior.
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        Social Media Strategy
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      Our sole purpose - attract and retain customers by consisiternly creating relevant valuable  content with the intention of changing or enchacing consumer behavior.
            </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        Social Media Optimization 
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      Our sole purpose - attract and retain customers by consisiternly creating relevant valuable  content with the intention of changing or enchacing consumer behavior.
            </div>
    </div>
  </div>
      </div>

      <div class="accordion" id="accordionPanelsStayOpenExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Content Development
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
      <div class="accordion-body">
        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
 
     </div>

    </div>
    </div>

    {/* Profissinoal Export For Your Bussiness */}

     <div className="row">
        <div className="col-md-12 professional">
            <h3 className='text-center'>Professional Exports for Your Bussiness</h3>
            <div className="underline-professional"> </div>
            <div className="professional-img">
                <img src="/web/profe.png"   alt="" />
            </div>
                <p className='text-center professinol-para'>AWESOME TEAM</p>
            <p className='text-center professinol-description'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis ratione amet dignissimos, et nemo nostrum non earum itaque excepturi aliquam fuga provident corrupti id dicta ea illo est placeat molestias totam odio. Necessitatibus, rerum quas? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magnam eligendi veritatis consequatur sint. Nesciunt doloremque praesentium nisi inventore iste officiis voluptatem repudiandae excepturi adipisci provident, at laboriosam voluptas assumenda, quibusdam cum nulla magni maxime, et natus nam explicabo aliquid accusantium asperiores. Unde pariatur quibusdam nobis odio? Animi cupiditate.</p>
        </div>

        {/*  contact us query form */}
        <h1 className='contact-h'>Tell Us About Your Project</h1>
        <div className='underline-contact'></div>
         <p className='contact-p'>Let's us help you get your business online and grow it which passion. </p>
         <QueryForm/>
        
       <div className="partner-main">
       <h2 className='text-center partner '>Partners</h2>
        <div className='contact-partner'>
                <img src="/web/profe.png"  height={50} width={50} alt="" />
                <img src="/web/profe.png"   height={50} width={50} alt="" />
                <img src="/web/profe.png"   height={50} width={50} alt="" />
                <img src="/web/profe.png"   height={50} width={50} alt="" />
                <img src="/web/profe.png"   height={50} width={50} alt="" />
        </div>
       </div>

         
     </div>

    </div>
    </>

  )
}
