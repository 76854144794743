import React from 'react'
import { Link } from 'react-router-dom'

export default function Team() {
  return (
    <> 
        <section id="doctors" className="doctors section   my-5 ">
        <div className="container section-title" data-aos="fade-up">
        <h2 className='text-center'>Our Team</h2>
        <p className='text-center' style={{textAlign:'justify', fontFamily:"sans-serif"}}>
             
We are a committed team of experts committed to developing solutions that have an impact on our users. To give you the greatest experience possible, our team is made up of knowledgeable professionals in a variety of domains, such as design, development, marketing, and customer service.
            </p>
      </div>
     <div className="container">

<div className="row gy-4">

    

  <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
    <div className="team-member d-flex align-items-start">
      <div className="pic"><img src="/img/karan.jpg"  className="img-fluid" alt=""/></div>
      <div className="member-info">
        <h4  style={{color:'#2C4964'}}>Karan Khanna</h4>
        <span style={{color:'#444444'}}>CEO of  Perfect Global Consultancy</span>
        <div className="social">
          <Link to=""><i className="bi bi-twitter-x"></i></Link>
          <Link target='_blank' to="https://www.facebook.com/karan.khanna"><i className="bi bi-facebook"></i></Link>
          <Link  target='_blank' to="https://www.instagram.com/karan_anup_khanna"><i className="bi bi-instagram"></i></Link>
          <Link target='_blank' to="https://www.linkedin.com/in/karan-khanna-28558110b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"> <i className="bi bi-linkedin"></i> </Link>
        </div>
      </div>
    </div>
  </div>
  {/* <!-- End Team Member --> */}

  <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
    <div className="team-member d-flex align-items-start">
      <div className="pic"><img src="/img/adi1.jpg" className="img-fluid" alt=""/></div>
      <div className="member-info">
        <h4 style={{color:'#2C4964'}}>Aditya Khanna</h4>
        <span style={{color:'#444444'}}>Project Manager</span>
        <div className="social">
          <Link to="#"><i className="bi bi-twitter-x"></i></Link>
          <Link to="#"><i className="bi bi-facebook"></i></Link>
          <Link to="#"><i className="bi bi-instagram"></i></Link>
          <Link to="#"> <i className="bi bi-linkedin"></i> </Link>
        </div>
      </div>
    </div>
  </div>
  {/* <!-- End Team Member --> */}

</div>
    </div>
   



    {/* <div className="container-fluid mt-2 ">
        <div className="row"> */}
          <div className="col-md-10 m-auto">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item ">
                <div className="col-lg-6 mm " data-aos="fade-up" data-aos-delay="100">
                    {/* team using top */}
                 <div className="team-member  d-flex align-items-start ">
                   <div className="pic "><img src="/img/vinay2.jpg"  className="img-fluid" alt=""/></div>
                       <div className="member-info">
                         <h4  style={{color:'#2C4964'}} className='text-center'>Vinay Chawala </h4>
                         <span style={{color:'#444444'}}>Data Analyst at Perfect Global Consultancy</span>
                        <div className="social social1">
                        <Link to="#"><i className="bi bi-twitter-x"></i></Link>
                        <Link to="#"><i className="bi bi-facebook"></i></Link>
                        <Link to="#"><i className="bi bi-instagram"></i></Link>
                        <Link to="#"> <i className="bi bi-linkedin"></i> </Link>
                   </div>
                </div>
               </div>
               </div>
                </div>

                {/* second card */}
                <div className="carousel-item ">
                <div className="col-lg-6 mm " data-aos="fade-up" data-aos-delay="100">
                    {/* team using top */}
                 <div className="team-member  d-flex align-items-start ">
                   <div className="pic "><img src="/img/ak.jpeg"  className="img-fluid" alt=""/></div>
                       <div className="member-info">
                         <h4  style={{color:'#2C4964'}} className='text-center'>Ankit Kumar</h4>
                         <span style={{color:'#444444'}}>Full Stack Web Developer at Perfect Global Consultancy</span>
                        <div className="social1 social">
                         <Link to="https://x.com/EnAnkit2402" target='_blank'><i className="bi bi-twitter-x"></i></Link>
                         <Link to="https://www.facebook.com/ankitkumr.kumr.3?mibextid=ZbWKwL" target='_blank'><i className="bi bi-facebook"></i></Link>
                         <Link to="https://www.instagram.com/dev__ankitkumar/profilecard/?igsh=eGJxMTNwdHhmcW1p" target='_blank'><i className="bi bi-instagram"></i></Link>
                         <Link to="https://www.linkedin.com/in/dev-ankitkumar" target='_blank'> <i className="bi bi-linkedin"></i> </Link>
                   </div>
                </div>
               </div>
               </div>
                </div>

                <div className="carousel-item active">
                <div className="col-lg-6 mm " data-aos="fade-up" data-aos-delay="100">
                    {/* team using top */}
                 <div className="team-member  d-flex align-items-start ">
                   <div className="pic "><img src="/img/pk.jpg"  className="img-fluid" alt=""/></div>
                       <div className="member-info">
                         <h4  style={{color:'#2C4964'}}>Parmanand Jha</h4>
                         <span style={{color:'#444444'}}>Full Stack Web Developer at Perfect Global Consultancy</span>
                        <div className="social social1">
                         <Link to="https://x.com/Parmana40262239?t=q6THGt7OnGtKCsCvPCNHFA&s=08" target='_blank'><i className="bi bi-twitter-x"></i></Link>
                         <Link to="https://www.facebook.com/parmanand.jha.50767?mibextid=ZbWKwL" target='_blank'><i className="bi bi-facebook"></i></Link>
                         <Link to="https://www.instagram.com/parmanand.jha.50767/profilecard/?igsh=M25wbW9weXRzOGpr" target='_blank' ><i className="bi bi-instagram"></i></Link>
                         <Link to="http://linkedin.com/in/devparmanand" target='_blank'> <i className="bi bi-linkedin"></i> </Link>
                   </div>
                </div>
               </div>
               </div>
                </div>
            
                
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon rounded-circle bg-dark " 
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon rounded-circle bg-dark " 
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          {/* </div>
        </div> */}
      </div>
      </section>
    </>
  )
}
