// import React, { useEffect } from 'react'
// import { getTestimonial } from '../../Store/ActionCreaters/TestimonialsActionCreaters'
// import { useDispatch, useSelector } from 'react-redux'

// export default function Testimonials() {
 
//   let dispatch = useDispatch()
//   let TestimonialStateData = useSelector((state)=>state.TestimonialStateData)

//  function getAPIData(){
//   dispatch(getTestimonial())
// }
// useEffect(()=>{
// 	(()=>{
//     getAPIData()
//   })()
// },[TestimonialStateData.length])
// return (
//     <>
 


//  <div id="testimonialCarousel" className="carousel slide" data-bs-ride="carousel">
//   <div className="carousel-inner">
//     <!-- Testimonial 1 -->
// 	{
// 	TestimonialStateData?.slice(0,6).map((item,index)=>{
// 			return(
// 				<div className="carousel-item active " key={index}> 
// 				<div className="card testimonial-card" >
// 	  <img src={`https://pgc.onrender.com/${item.pic}`} alt="No img" height={"60px"} width={"60px"} className="mx-auto d-block rounded-circle"/>
//       <div className="testimonial-item">
//         <p className="testimonial-text">{item?.message}</p>
//         <p className="testimonial-author">{item?.name}</p>
//         <p className="testimonial-role">{item?.profile}</p>
//       </div>
// 	  </div>
//     </div>
// 			)
// 		})
// 	}
//   </div>
  

//   <button className="carousel-control-prev" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="prev">
//     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Previous</span>
//   </button>
//   <button className="carousel-control-next" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="next">
//     <span className="carousel-control-next-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Next</span>
//   </button>
// </div> 
//     </>
//   )
// }












"use client";
import { getTestimonial } from "../../Store/ActionCreaters/TestimonialsActionCreaters"
import { useDispatch, useSelector } from 'react-redux'
// import Image from "next/image";
// import db from "@/db/testimonial.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { useEffect } from "react";
// import { testimonial } from "@/models/testimonial";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Testimonial= () => {
  let dispatch = useDispatch()
    let TestimonialStateData = useSelector((state)=>state.TestimonialStateData)
  
   function getAPIData(){
    dispatch(getTestimonial())
  }
  useEffect(()=>{
  	(()=>{
      getAPIData()
    })()
  },[TestimonialStateData.length])
  return (
    <Slider {...settings}>
      {TestimonialStateData.map((item, index) => {
        return (
          <aside key={index} className="p-4 " >
            <div className="card-testi m-auto " >
            {/* <div className="p-8 rounded-lg bg-light bg-opacity-10 shadow-md flex flex-col items-center justify-center"> */}
            <i class="bi bi-quote fs-3 text-success "></i>
              <p className="text-base text-darkGray text-center mt-6 mb-8">
                {item.message}
              </p>
              <img src={`https://pgc.onrender.com/${item.pic}`}
                alt={item.name}
                width={60}
                height={60}
                className=" rounded-circle mb-4 my-2 testi-img"
              />
              <h5 className="text-center  text-black " >
                {item.name}
                <br />
                <small className="text-success profile">
                  {item.profile}
                </small>
              </h5>
            </div>
          </aside>
        );
      })}
    </Slider>
  );
};

export default Testimonial;
