import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getLicense} from "../../Store/ActionCreaters/LicenseActionCreaters"
import { useDispatch, useSelector } from "react-redux";

export default function Navbar() {
  let dispatch =useDispatch()
  let LicenseStateData = useSelector((x)=>x.LicenseStateData)

  function getAPIData (){
    dispatch(getLicense())
  }

  const goToTop =()=>{
    setTimeout(() => {
        // refreshPage()
        window.location.reload()
      },10)
    // window.scrollTo({top:0,left:0 ,behavior:"smooth"})
}

  useEffect(()=>{
    getAPIData()
  },[LicenseStateData?.length])

  return (
    <>
      {/* <!-- Navbar Start--> */}

      <nav className="navbar navbar-expand-lg navbar-light bg-light ">
        <div className="container-fluid">
         <h1>
            <Link className="navbar-brand text-dark mx-5" to="/">
              <img src='/img/logo7.png' alt="logo"  className="logo" />
            </Link>
          </h1>

          <button
            className="navbar-toggler navbar-toggle-btn"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          >
          <span className=" mobile-nav-toggle  material-symbols-outlined text-dark navbar-link fs-5">
           menu
          </span>
       
          </button>
          <div
            className="collapse  navbar-collapse"
            id="navbarSupportedContent">
            <div className="navbar-nav py-3 mx- py-lg-0">
              <NavLink
                to="/"
                className="nav-item nav-link  navbar-link  mx-5 text-dark"
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                className="nav-item nav-link  navbar-link mx-5 "
              >
                About Us
              </NavLink>
              <NavLink to="" className="nav-item nav-link navbar-link dropbtn me-5  text-dark">
              
                <div className="dropdown ">
                   Services                   <i class="bi bi-caret-down-fill"></i>
                  <div className="dropdown-content">
                    {/* <Link to='/fssai-license' >FSSAI License Registration </Link>
                    <Link to='/DDPC-Fire-reg' >DPCC/ Fire License Registration  </Link>
                    <Link to='/delhi-police-reg' >Delhi Police (PCC/ FIR/ Report/  etc.)Registration </Link>
                    <Link to='/MCD-factory-reg' >MCD Trade (Factory Trade) Registration </Link>
                    <Link to='/msme-reg' > MSME (Udyam) Registration </Link>
                    <Link to='/import-export-reg' >Import & Export (RCMC) Registration</Link>
                     */}
                    {/* {
                      LicenseStateData?.slice(0,5).map((item,index)=>{
                        return(
                        <Link to={`/services/${item._id}`} key={index} onClick={goToTop} >{item.name}</Link>
                        )
                      })
                    } */}

                    <Link to='/eating-house' >Delhi Police(Eating House)</Link>
                    <Link to='/webdevelopment' >Web Development</Link>
                    <Link to='/dpcc-license' >DPCC License</Link>
                    <Link to='/mcd-health-trade' >MCD Health-Trade License </Link>
                    <Link to='/export-import' >Export Import</Link>
                    <Link to='/fssai' >FSSAI (State Lic.)</Link>
                    <Link to='/dda-property' >DDA Property Reg.</Link>
                    <Link to='/allservices-page' >View More Services</Link>

                  </div>
                </div>
               
              </NavLink>
              <NavLink to="" className="nav-item nav-link navbar-link dropbtn  text-dark">
                 {/* website  */}
                 <div className="dropdown ">
                   Web Development
                   <i class="bi bi-caret-down-fill"></i>
                  <div className="dropdown-content">
                    {/* <Link to='/fssai-license' >FSSAI License Registration </Link>
                    <Link to='/DDPC-Fire-reg' >DPCC/ Fire License Registration  </Link>
                    <Link to='/delhi-police-reg' >Delhi Police (PCC/ FIR/ Report/  etc.)Registration </Link>
                    <Link to='/MCD-factory-reg' >MCD Trade (Factory Trade) Registration </Link>
                    <Link to='/msme-reg' > MSME (Udyam) Registration </Link>
                    <Link to='/import-export-reg' >Import & Export (RCMC) Registration</Link>
                     */}
                    {/* {
                      LicenseStateData?.slice(0,5).map((item,index)=>{
                        return(
                        <Link to={`/services/${item._id}`} key={index} onClick={goToTop} >{item.name}</Link>
                        )
                      })
                    } */}

                    {/* <Link to='/coming' >Social Media Management</Link> */}
                    <Link to='/socialmedia' >Social Media Management</Link>
                    <Link to='/coming' >Social Media Marketing</Link>
                    <Link to='/coming' >Web Development & Website Design  </Link>
                    <Link to='/coming' >Search Engine Optimization</Link>
                    <Link to='/coming' >Pay Per Click Management</Link>
                    <Link to='/coming' >Influencer Marketing</Link>
                    <Link to='/coming' >Graphic Design</Link>
                    <Link to='/coming' >Logo Design</Link>
                    <Link to='/coming' >Partner Services (Amazon, Flipkart)</Link>

                  </div>
                </div>
              </NavLink>
              <NavLink to="/contact-us" className="nav-item nav-link navbar-link mx-5 ">
                Contact Us
              </NavLink>

              <NavLink to="/login" className="nav-item nav-link navbar-link mx-5 float-end " >Login </NavLink>
            </div>
          </div>
        </div>
      </nav>
      {/* <!-- Navbar End --> */}
    </>
  );
}
