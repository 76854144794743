import React from 'react'
import Slider from "./partials/Slider"
import Service from './partials/Service'

import About from './partials/About'
import Client from './partials/Client'
import Testimonials from './partials/Testimonials'
import QueryForm from './partials/QueryForm'
import OurServices from './partials/OurServices'
import { Link } from 'react-router-dom'
import Team from './Team'

export default function Home() {
  return (
   <>
   <header>
    <Slider/>
    <About/>
    <div className="whatsapp">
    <Link to="https://wa.me/+919971522879" target='_blank' >  <img src="./img/whatapp.svg" alt='whatsapp'  /> </Link>
    </div>
    <Service/>
    <Client/>

    <section className="py-5">
        <div className="container m-auto px-4 " style={{backgroundColor:"#f7faf9"}}>
          <h2 className="text-4xl text-center lg:text-6xl font-bold text-dark mb-3 uppercase">
          {/* <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-darkGray mb-3 uppercase"> */}
            Testimonials
          </h2>
          <p className="text-sm text-center text-dark w-full w-100">
          What Our Happy Customers Say About Us.
          </p>
          <div className="mt-5 testi-main">
            {/* <Testimonials /> */}
    <Testimonials/>
             {/* <Testimonial/> */}
          </div>
        </div>
      </section>

    <QueryForm/>
    <Team/>
    <OurServices/>
    </header>

   </>
  )
}
