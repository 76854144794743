import React, { useEffect, useState } from 'react'
import SideBar from '../partials/SideBar'
import { Link } from 'react-router-dom';


export default function AdminHome() {
let[data,setData]=useState([])
  const removeLogin=(()=>{
    localStorage.removeItem("login")
    localStorage.removeItem("name")
    localStorage.removeItem("username")
    localStorage.removeItem("token")
    localStorage.removeItem("userid")
    localStorage.removeItem("role")
    
  })

  // getAPIData
 async function getAPIData(){
        let response = await fetch("https://pgc.onrender.com/api/admin",{
            method:"GET",
            headers:{
                "content-type":"application/json"
            }
        })
        response =await response.json()
      
        if(response.result==="Done"){
          let item = response.data.filter((x)=>x.role==="Admin")
            setData(item)
        }
        else
        setData([])
 }

    useEffect(()=>{
         getAPIData()
    },[data?.length])

  return (
    <>
    <div className="container-fluid">
        <div className="row my-2">
            <div className="col-md-3 ">
           <div className="admin-info">
     {data.map((item,index)=>{
      return(
        <h6 className='text-center text-light ' key={index}>{item?.name}</h6>
      )
     })}
           <p className='text-center'>(Admin) </p>
           </div>
                <SideBar/>
            </div>
            <div className="col-md-9 table-responsive">
             <h4 className='text-center text-light bg-primary p-2'>Admin Profile <Link to="/login" onClick={removeLogin}><i className="bi bi-box-arrow-right text-light float-end pe-auto"></i></Link>  </h4>
             <table className='table table-bordered table-hover ' id='dataTable'>
             <thead>
                    <tr>
                        <th>User Name</th>
                        <th> Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        data?.map((item, index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.username}</td>
                                    <td>{item.name}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.email}</td>
                                    <td>{item.role}</td>

                                </tr>
                            )
                        })
                    }
             
                    
                    </tbody>
            </table> 
            <Link to="/admin/profile/update"  style={{textDecoration:"none"}}> <h4 className='text-center text-light bg-primary py-2' >Update Profile </h4></Link>

            </div>
        </div>
    </div>
    </>
  )
}
